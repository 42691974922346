<template>
    <div class="home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-dark-grayscale">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0 0.49803921568627" />
                        <feFuncG type="table" tableValues="0 0.49803921568627" />
                        <feFuncB type="table" tableValues="0 0.49803921568627" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-grayscale">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0 1" />
                        <feFuncG type="table" tableValues="0 1" />
                        <feFuncB type="table" tableValues="0 1" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-purple-yellow">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0.54901960784314 0.98823529411765" />
                        <feFuncG type="table" tableValues="0 1" />
                        <feFuncB type="table" tableValues="0.71764705882353 0.25490196078431" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-blue-red">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0 1" />
                        <feFuncG type="table" tableValues="0 0.27843137254902" />
                        <feFuncB type="table" tableValues="0.5921568627451 0.27843137254902" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-midnight">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0 0" />
                        <feFuncG type="table" tableValues="0 0.64705882352941" />
                        <feFuncB type="table" tableValues="0 1" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-magenta-yellow">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0.78039215686275 1" />
                        <feFuncG type="table" tableValues="0 0.94901960784314" />
                        <feFuncB type="table" tableValues="0.35294117647059 0.47058823529412" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-purple-green">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0.65098039215686 0.40392156862745" />
                        <feFuncG type="table" tableValues="0 1" />
                        <feFuncB type="table" tableValues="0.44705882352941 0.4" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 0 0" width="0" height="0" focusable="false" role="none"
            style="visibility: hidden; position: absolute; left: -9999px; overflow: hidden;">
            <defs>
                <filter id="wp-duotone-blue-orange">
                    <feColorMatrix color-interpolation-filters="sRGB" type="matrix"
                        values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 " />
                    <feComponentTransfer color-interpolation-filters="sRGB">
                        <feFuncR type="table" tableValues="0.098039215686275 1" />
                        <feFuncG type="table" tableValues="0 0.66274509803922" />
                        <feFuncB type="table" tableValues="0.84705882352941 0.41960784313725" />
                        <feFuncA type="table" tableValues="1 1" />
                    </feComponentTransfer>
                    <feComposite in2="SourceGraphic" operator="in" />
                </filter>
            </defs>
        </svg>
        <div data-elementor-type="wp-post" data-elementor-id="17666" class="elementor elementor-17666">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-3f3c5267 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle wdp-sticky-section-no lws-sticky-section-no"
                data-id="3f3c5267" data-element_type="section" v-bind:style="{
                    'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/' + client.foto_cover + ')',
                    'background-repeat': 'no-repeat',
                    'transition-duration': '5000ms',
                    'background-size': 'cover',
                    'background-position': 'center top',
                    'transition-timing-function': 'ease-in-out',
                    'animation-duration': '5000ms',
                    'animation-name': 'zoom-out',
                    'animation-iteration-count': 'infinite',
                    'shape-outside': 'polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 4vw))',
                    'transform': 'scale(1)',
                    'transition': 'transform 2s ease-in-out'
                }">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
                        <path class="elementor-shape-fill" d="M0 0L2600 0 2600 69.1 0 0z" />
                        <path class="elementor-shape-fill" style="opacity:0.5" d="M0 0L2600 0 2600 69.1 0 69.1z" />
                        <path class="elementor-shape-fill" style="opacity:0.25" d="M2600 0L0 0 0 130.1 2600 69.1z" />
                    </svg>
                </div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4811528 wdp-sticky-section-no lws-sticky-section-no"
                        data-id="4811528" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-1a8cf1d4 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="1a8cf1d4" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">

                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-44db492a animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="44db492a" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;lightSpeedIn&quot;,&quot;_animation_delay&quot;:3000,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">The Wedding Of</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-1de7b6cc animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="1de7b6cc" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;lightSpeedIn&quot;,&quot;_animation_delay&quot;:3500,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default"
                                        v-if="client.hajat == 'pria'" style="color: #d9b657;">
                                        {{ client.nama_pria }} &amp; {{ client.nama_wanita }}</h2>
                                    <h2 class="elementor-heading-title elementor-size-default"
                                        v-if="client.hajat == 'wanita'" style="color: #d9b657;">
                                        {{ client.nama_wanita }} &amp; {{ client.nama_pria }}</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-4c66e100 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="4c66e100" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;lightSpeedIn&quot;,&quot;_animation_delay&quot;:4000,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default" style="color: #d19c47; font-weight: bold;">{{
                                        moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-11bbeb4c wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="11bbeb4c" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-42a6b07c elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle wdp-sticky-section-no lws-sticky-section-no"
                data-id="42a6b07c" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style="background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2e40f069 wdp-sticky-section-no lws-sticky-section-no"
                        data-id="2e40f069" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-6e7da2f4 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="6e7da2f4" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-100427fa wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="100427fa" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #1a1a1a">The honor of your presence is
                                        requested.<br>At the marriage of</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5bbec774 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="5bbec774" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-26abb9e1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="26abb9e1" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7538bb7 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="7538bb7" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-b7e7f87 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                                data-id="b7e7f87" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1249" height="1249"
                                                        :src="client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita"
                                                        class="attachment-full size-full wp-image-17731" alt=""
                                                        loading="lazy"
                                                        :srcset="`${client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 1249w',
                                                        ${client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 300w',
                                                        ${client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 1024w',
                                                        ${client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 768w',
                                                        ${client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 150w8w`"
                                                        sizes="(max-width: 1249px) 100vw, 1249px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-599cf530 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="599cf530" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-572d2337 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="572d2337" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="font-family: 'Dancing Script', cursive; color: #1a1a1a;">{{ client.nama_wanita }} </h2>
                                                    <h2 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="font-family: 'Dancing Script', cursive; color: #1a1a1a;">{{ client.nama_pria }} </h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4414bb92 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="4414bb92" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1af2ab87 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="1af2ab87" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="color: #1a1a1a">{{ client.gelar_wanita }}</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="color: #1a1a1a">{{ client.gelar_pria }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2a4826b4 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="2a4826b4" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">                                                    
                                                    <h3 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'pria'" style="color: #d19c47;">Son Of</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'wanita'" style="color: #d19c47;">Daughter Of</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2b757af2 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="2b757af2" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="color: #d19c47">{{ client.nama_ayah_wanita }} &
                                                        {{ client.nama_ibu_wanita }}</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="color: #d19c47">{{ client.nama_ayah_pria }} &
                                                        {{ client.nama_ibu_pria }}</h3>
                                                </div>
                                            </div>
                                            <!-- <div class="elementor-element elementor-element-55da7a78 elementor-align-center animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="55da7a78" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;pulse&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a href="https://www.instagram.com/yunievaka/"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fab fa-instagram"></i>
                                                                </span>
                                                                <span class="elementor-button-text">yunievaka</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="elementor-element elementor-element-2627d1c9 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="2627d1c9" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-52f9494e elementor-widget-divider--view-line_text animated-slow elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                data-id="52f9494e" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="divider.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator" style="color: #d19c47;">
                                            <span class="elementor-divider__text elementor-divider__element" style="color: #d9b657;">
                                                & </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-38e71a67 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="38e71a67" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6621c847 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="6621c847" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-512d0e45 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                                data-id="512d0e45" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="2367" height="2367"
                                                        :src="client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita"
                                                        class="attachment-full size-full wp-image-17731" alt=""
                                                        loading="lazy"
                                                        :srcset="`${client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 1249w',
                                                        ${client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 300w',
                                                        ${client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 1024w',
                                                        ${client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 768w',
                                                        ${client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita} 150w8w`"
                                                        sizes="(max-width: 2367px) 100vw, 2367px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6873da45 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="6873da45" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-4cdc56ea wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="4cdc56ea" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="font-family: 'Dancing Script', cursive; color: #1a1a1a;">{{ client.nama_pria }} </h2>
                                                    <h2 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="font-family: 'Dancing Script', cursive; color: #1a1a1a;">{{ client.nama_wanita }} </h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-59e9f2d7 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="59e9f2d7" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-5d09038c animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="5d09038c" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="color: #1a1a1a">{{ client.gelar_pria }}</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="color: #1a1a1a">{{ client.gelar_wanita }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4aade778 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="4aade778" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'wanita'" style="color: #d19c47;">Son Of</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'pria'" style="color: #d19c47;">Daughter Of</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-dfed2d0 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="dfed2d0" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'wanita'" style="color: #d19c47">{{ client.nama_ayah_pria }} &
                                                        {{ client.nama_ibu_pria }}</h3>
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.hajat == 'pria'" style="color: #d19c47">{{ client.nama_ayah_wanita }} &
                                                        {{ client.nama_ibu_wanita }}</h3>
                                                </div>
                                            </div>
                                            <!-- <div class="elementor-element elementor-element-310bef1b elementor-align-center animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="310bef1b" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;pulse&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a href="https://www.instagram.com/muhammad_nur_badruddin/"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fab fa-instagram"></i>
                                                                </span>
                                                                <span
                                                                    class="elementor-button-text">muhammad_nur_badruddin</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="elementor-element elementor-element-741999b4 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="741999b4" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-48d3a16f elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="48d3a16f" data-element_type="section"
                style="position: relative; padding-bottom: 6em; background-image: url('assets/images/header-opening-bottom-2.png');background-color: #1a1a1a; background-size: 100% 100px, cover; background-repeat: no-repeat; background-position: center calc(100% + 2rem), center center; "
                >
                <!-- <div class="elementor-background-overlay"></div> -->
                <div class="elementor-shape elementor-shape-top" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
                        <path class="elementor-shape-fill" d="M0 0L2600 0 2600 69.1 0 0z" />
                        <path class="elementor-shape-fill" style="opacity:0.5" d="M0 0L2600 0 2600 69.1 0 69.1z" />
                        <path class="elementor-shape-fill" style="opacity:0.25" d="M2600 0L0 0 0 130.1 2600 69.1z" />
                    </svg>
                </div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-32c51d0e wdp-sticky-section-no lws-sticky-section-no"
                        data-id="32c51d0e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-314dfeff wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="314dfeff" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7fcd13f1 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="7fcd13f1" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Save The Date</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-73ab3b5b wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="73ab3b5b" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">In the arithmetic of love,
                                        one plus one equals everything,<br>and two minus one equals nothing.</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5030b0ff animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-landingstarwedding-countdown"
                                data-id="5030b0ff" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="landingstarwedding-countdown.default">
                                <div class="elementor-widget-container">

                                    <div class="wpkoi-elements-countdown-wrapper" >
                                        <div
                                            class="wpkoi-elements-countdown-container wpkoi-elements-countdown-label-block ">
                                            <ul id="wpkoi-elements-countdown-5030b0ff"
                                                class="wpkoi-elements-countdown-items">
                                                <li class="wpkoi-elements-countdown-item">
                                                    <div class="wpkoi-elements-countdown-days"><span data-days
                                                            class="wpkoi-elements-countdown-digits" style="color: #d9b657;">{{ days }}</span><span
                                                            class="wpkoi-elements-countdown-label" style="color: #d19c47;">D</span></div>
                                                </li>
                                                <li class="wpkoi-elements-countdown-item">
                                                    <div class="wpkoi-elements-countdown-hours"><span data-hours
                                                            class="wpkoi-elements-countdown-digits" style="color: #d9b657;">{{ hours }}</span><span
                                                            class="wpkoi-elements-countdown-label"  style="color: #d19c47;">H</span></div>
                                                </li>
                                                <li class="wpkoi-elements-countdown-item">
                                                    <div class="wpkoi-elements-countdown-minutes"><span data-minutes
                                                            class="wpkoi-elements-countdown-digits" style="color: #d9b657;">{{ minutes
                                                            }}</span><span class="wpkoi-elements-countdown-label"  style="color: #d19c47;">M</span>
                                                    </div>
                                                </li>
                                                <li class="wpkoi-elements-countdown-item">
                                                    <div class="wpkoi-elements-countdown-seconds"><span data-seconds
                                                            class="wpkoi-elements-countdown-digits" style="color: #d9b657;">{{ seconds
                                                            }}</span><span class="wpkoi-elements-countdown-label"  style="color: #d19c47;">S</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7985bde4 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="7985bde4" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-71fe1b7e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="71fe1b7e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2df29ca2 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="2df29ca2" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-fd289f4 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                                data-id="fd289f4" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="2560" height="1707"
                                                        :src="'https://admin.itsyourdayofficial.com/gallery/' + img2.file"
                                                        class="attachment-full size-full wp-image-17688" alt=""
                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4ad9dc9d wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="4ad9dc9d" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;slideInRight&quot;,&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-52683e89 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="52683e89" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">Akad </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-6ffd0d25 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="6ffd0d25" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">{{
                                                        client.nama_venue }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-76d08586 elementor-widget-divider--view-line wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                                data-id="76d08586" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;fadeIn&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;}"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-29a2a0a7 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="29a2a0a7" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">{{
                                                        moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}
                                                    </h3>
                                                </div>
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.tgl_akad_selesai == null" style="color: #d9b657;">{{
                                                            moment(client.tgl_akad).format("HH:mm") }} </h3>
                                                    <h3 class="elementor-heading-title elementor-size-default" v-else style="color: #d9b657;">{{
                                                        moment(client.tgl_akad).format("HH:mm") }} - {{
                                                        moment(client.tgl_akad_selesai).format("HH:mm") }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-7d1e5a83 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="7d1e5a83" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-html="client.alamat_venue" style="color: #d9b657;"></h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-55a9ef86 elementor-align-center elementor-mobile-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="55a9ef86" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper"
                                                        v-if="client.url_maps_resepsi !== null">
                                                        <a :href="client.url_maps" target="_blank"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button" style="background-color: #d19c47;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                                </span>
                                                                <span class="elementor-button-text">Google Map</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div class="elementor-button-wrapper"
                                                        v-else-if="config.maps_section !== 1">
                                                        <a :href="client.url_maps" target="_blank"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button" style="background-color: #d19c47;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                                </span>
                                                                <span class="elementor-button-text">Google Map</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div class="elementor-button-wrapper"
                                                        v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                                        <!-- BLANK -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1573b26d wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="1573b26d" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2d8e215c elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="2d8e215c" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-58a70b5c wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="58a70b5c" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-582ebb8 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                                data-id="582ebb8" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1707" height="2560"
                                                        :src="'https://admin.itsyourdayofficial.com/gallery/' + img3.file"
                                                        class="attachment-full size-full wp-image-17691" alt=""
                                                        loading="lazy" sizes="(max-width: 1707px) 100vw, 1707px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-47bf193c wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="47bf193c" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;slideInRight&quot;,&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-2e24228a wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="2e24228a" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">Reception
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-76730832 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="76730832" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">{{
                                                        client.nama_venue_resepsi }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1b7506dd elementor-widget-divider--view-line wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                                data-id="1b7506dd" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;fadeIn&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;}"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-560d2689 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="560d2689" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">{{
                                                        moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY")
                                                    }}
                                                    </h3>
                                                </div>
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-if="client.tgl_resepsi_selesai == null" style="color: #d9b657;">{{
                                                            moment(client.tgl_resepsi).format("HH:mm") }} </h3>
                                                    <h3 class="elementor-heading-title elementor-size-default" v-else style="color: #d9b657;">{{
                                                        moment(client.tgl_resepsi).format("HH:mm") }} - {{
                                                        moment(client.tgl_resepsi_selesai).format("HH:mm") }}</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4b979691 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="4b979691" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"
                                                        v-html="client.alamat_venue_resepsi" style="color: #d9b657;"></h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-6367edc6 elementor-align-center elementor-mobile-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="6367edc6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper"
                                                        v-if="client.url_maps_resepsi !== null">
                                                        <a :href="client.url_maps_resepsi" target="_blank"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button" style="background-color: #d19c47;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                                </span>
                                                                <span class="elementor-button-text">Google Map</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div class="elementor-button-wrapper"
                                                        v-else-if="config.maps_section !== 1">
                                                        <a :href="client.url_maps" target="_blank"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button" style="background-color: #d19c47;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                                </span>
                                                                <span class="elementor-button-text" >Google Map</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div class="elementor-button-wrapper"
                                                        v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                                        <!-- BLANK -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-40cc69e9 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="40cc69e9" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section v-if="config.maps_section !== 0"
                                class="elementor-section elementor-inner-section elementor-element elementor-element-53c9230b elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="53c9230b" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7104cb20 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="7104cb20" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-356b0794 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="356b0794" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1c601c1d wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="1c601c1d" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Google Maps
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4eade22 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="4eade22" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <div class="row">
                                                        <iframe class="ftco-animate" :src="client.embed" width="95%"
                                                            height="450"
                                                            style="border:0; border-radius: 20px !important; margin: auto; padding-top: 30px;"
                                                            allowfullscreen="" loading="lazy"
                                                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-73a6763c elementor-align-center elementor-mobile-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-modal-popup"
                                                data-id="73a6763c" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="weddingpress-modal-popup.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a :href="client.url_maps" target="_blank"
                                                            style="background-color: #d19c47;"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button" >
                                                            <span class="elementor-button-content-wrapper">
                                                                <span
                                                                    class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                                                                </span>
                                                                <span class="elementor-button-text">Google Maps</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-53c9230b elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="53c9230b" data-element_type="section" >
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7104cb20 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="7104cb20" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-356b0794 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="356b0794" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1c601c1d wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="1c601c1d" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Attention
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4eade22 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="4eade22" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">Due to the
                                                        pandemic, our wedding will be held in accordance with health
                                                        protocols. We expect all guest to follow health protocols inside the
                                                        venue. To comply with COVID-19 protocols, we request our guests to:
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-73a6763c elementor-align-center elementor-mobile-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-modal-popup"
                                                data-id="73a6763c" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="weddingpress-modal-popup.default">
                                                <div class="elementor-widget-container">
                                                    <div class="exad-modal">
                                                        <div class="exad-modal-wrapper">
                                                            <div class="elementor-button-wrapper">
                                                                <a href="#"
                                                                    class="exad-modal-image-action image-modal"
                                                                    data-exad-modal="#exad-modal-73a6763c"
                                                                    data-exad-overlay="yes" >
                                                                    <span class="elementor-button"
                                                                        role="button" style="background-color: #d19c47;">
                                                                        <span
                                                                            class="elementor-button-content-wrapper">
                                                                            <span
                                                                                style="padding-left:5px;">CLICK -
                                                                                COVID19
                                                                                PROTOCOL
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div id="exad-modal-73a6763c"
                                                                class="exad-modal-item modal-vimeo zoom-in image">
                                                                <div class=" exad-modal-content">
                                                                    <div class="exad-modal-element "><img decoding="async"
                                                                            src="assets/wp-content/uploads/2023/02/protocol-bhs-imggris-1-scaled-1.jpg" />
                                                                        <div class="exad-close-btn"><span></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="exad-modal-overlay" data-exad_overlay_click_close="yes">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-17d844c9 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="17d844c9" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-2199e515 elementor-section-full_width elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="2199e515" data-element_type="section"  style="background-color: #fbf9e7;">
                <div class="elementor-element elementor-element-23535113 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                    data-id="23535113" data-element_type="widget"
                    data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                    data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                        <h3 class="elementor-heading-title elementor-size-default" style="padding-top: 30px; color: #1a1a1a;">Gallery</h3>
                    </div>
                </div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7016b03e wdp-sticky-section-no lws-sticky-section-no"
                        data-id="7016b03e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-6b397287 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-gallery"
                                data-id="6b397287" data-element_type="widget"
                                data-settings="{&quot;gallery_layout&quot;:&quot;masonry&quot;,&quot;columns_mobile&quot;:2,&quot;image_hover_animation&quot;:&quot;grow&quot;,&quot;content_hover_animation&quot;:&quot;exit-zoom-in&quot;,&quot;columns&quot;:6,&quot;columns_tablet&quot;:3,&quot;lazyload&quot;:&quot;yes&quot;,&quot;gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;link_to&quot;:&quot;file&quot;,&quot;overlay_background&quot;:&quot;yes&quot;}"
                                data-widget_type="gallery.default">
                                <div class="elementor-widget-container">
                                    <div class="container-fluid px-md-4">
                                        <div class="row justify-content-center ">
                                            <div class="col-md-12 ">
                                                <vue-horizontal responsive :button="false" ref="horizontal"
                                                    @scroll-debounce="onScroll">
                                                    <div class="item" v-for="(item, index) in gallery" :key="index">
                                                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file"
                                                            class="gallery img d-flex align-items-center justify-content-center"
                                                            alt="...">
                                                    </div>
                                                </vue-horizontal>
                                                <div class="pagination justify-content-center">
                                                    <nav>
                                                        <button @click="prev"
                                                            :class="{ 'active': hasPrev, 'inactive': !hasPrev }">
                                                            <i class="lni lni-chevron-left"></i>
                                                        </button>
                                                        <button @click="next"
                                                            :class="{ 'active': hasNext, 'inactive': !hasNext }">
                                                            <i class="lni lni-chevron-right"></i>
                                                        </button>
                                                        <!-- <button>test</button> -->
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-65b4820 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle wdp-sticky-section-no lws-sticky-section-no"
                data-id="65b4820" data-element_type="section"
                style="background-image: url('assets/images/header-opening-top-2.png'), url('assets/images/header-opening-bottom-2.png'); 
                background-color: #1a1a1a; 
                background-size: 100% 100px, 100% 100px, cover, cover; 
                background-repeat: no-repeat; 
                background-position: center calc(0% - 1rem), center calc(100% + 2rem + 30px), center center, center center; 
                padding-top: 5em;"
                >
                <!-- <div class="elementor-background-overlay"></div> -->
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-421b80fc wdp-sticky-section-no lws-sticky-section-no"
                        data-id="421b80fc" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-3bb196bb wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="3bb196bb" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Wedding Wish</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-70b81738 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-text-editor"
                                data-id="70b81738" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p style="color: #d9b657;">Send Prayers &amp;Greetings to the Bride and Groom</p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-59c2f287 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="59c2f287" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-27616600 elementor-align-justify animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-guestbook"
                                data-id="27616600" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;slideInUp&quot;,&quot;_animation&quot;:&quot;slideInUp&quot;}"
                                data-widget_type="weddingpress-guestbook.default">
                                <div class="elementor-widget-container">


                                    <div class="guestbook-box-content elementor-comment-box-wrapper" data-id="082216611695">
                                        <div class="comment-form-container">
                                            <form id="post-guestbook-box">
                                                <div class="guestbook-label">
                                                    <label class="elementor-screen-only">
                                                        Name </label>
                                                </div>
                                                <input v-model="submit.name" class="form-control" type="text"
                                                    name="guestbook-name" placeholder="Name" required>
                                                <span v-if="errors.name"
                                                    style="margin-bottom:10px; background:rgb(255 115 0); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                                    class="badge badge-danger rounded-pill">
                                                    Please input your name
                                                </span>

                                                <select v-model="submit.confirmation" class="form-control">
                                                    <option value="" disabled selected>Konfirmasi Kehadiran</option>
                                                    <option value="hadir">Hadir</option>
                                                    <option value="mungkin">Mungkin Hadir</option>
                                                    <option value="tidak">Tidak Hadir</option>
                                                </select>
                                                <span v-if="errors.confirmation"
                                                    style="margin-bottom:10px; background:rgb(255 115 0); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                                    class="badge badge-danger rounded-pill">
                                                    Please confirm your attendance
                                                </span>

                                                <div class="guestbook-label">
                                                    <label class="elementor-screen-only">
                                                        Message </label>
                                                </div>
                                                <textarea v-model="submit.comment" class="form-control" rows="3"
                                                    name="guestbook-message" placeholder="Message" required></textarea>
                                                <span v-if="errors.comment"
                                                    style="margin-bottom:10px; background:rgb(255 115 0); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                                    class="badge badge-danger rounded-pill">
                                                    Please input your message
                                                </span>
                                                <div class="elementor-button-wrapper">
                                                    <button @click.prevent="post" type="submit"
                                                        class="elementor-button-link elementor-button elementor-size-sm" style="background-color: #d19c47;">
                                                        Submit </button>
                                                </div>
                                            </form>
                                        </div>

                                        <div id="hidden-avatar" style="display:none;">
                                            <img decoding="async"
                                                src="assets/wp-content/uploads/2023/02/blank.png" title="QW-009"
                                                alt="QW-009" loading="lazy" />
                                        </div>

                                        <div class="guestbook-list">

                                            <div class="user-guestbook" v-for="h in hopes" :key="h.id">
                                                <div>
                                                    <img decoding="async" src="assets/images/wedding-rings-2.png"
                                                        title="QW-009" alt="QW-009" loading="lazy" />
                                                </div>
                                                <div class="guestbook" style="background-color: #fbf9e7;">
                                                    <a class="guestbook-name">{{h.name}}</a>
                                                    <span class="wdp-confirm" style="background-color: #fbf9e7;">
                                                        <i class="fas fa-check-circle"></i>
                                                    </span>
                                                    <div>
                                                        <p v-if="h.confirmation == 'hadir'" style="font-size:12px;font-style: oblique; margin-bottom: 0.5rem;">Hadir</p>
                                                        <p v-if="h.confirmation == 'mungkin'" style="font-size:12px;font-style: oblique; margin-bottom: 0.5rem;">Mungkin Hadir</p>
                                                        <p v-if="h.confirmation == 'tidak'" style="font-size:12px;font-style: oblique; margin-bottom: 0.5rem;">Tidak Hadir</p>
                                                    </div>
                                                    <div class="guestbook-message" >
                                                        <p v-html="h.comment"></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-78062f7d wdp-sticky-section-no lws-sticky-section-no"
                        data-id="78062f7d" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div v-if="config.rsvp_by_wa_section !== 0"
                                class="elementor-element elementor-element-23435875 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="23435875" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Rsvp</h3>
                                </div>
                            </div>
                            <div v-if="config.rsvp_by_wa_section !== 0"
                                class="elementor-element elementor-element-3be037ab animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-text-editor"
                                data-id="3be037ab" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p style="color: #d9b657;">We, along with our families will be looking forward to your presence. We would be
                                        incredibly grateful if you came to celebrate our love together with us.</p>
                                </div>
                            </div>
                            <div v-if="config.rsvp_by_wa_section !== 0"
                                class="elementor-element elementor-element-1a683af4 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-forms"
                                data-id="1a683af4" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;slideInUp&quot;}"
                                data-widget_type="weddingpress-forms.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-wdp-form-wrapper elementor-wdp-form-button-align-fullwidth">
                                        <form method="get" class="wdp-form wdp-wa-form" id="wdp-wa-form-1a683af4">
                                            <div class="wdp-form-fields-wrapper">
                                                <div class="wdp-form-field-nama">
                                                    <label for="wdp-form-nama-1a683af4" class="elementor-screen-only">
                                                        Name </label>
                                                    <input type="text" name="wdp-form-nama" class="wdp-form-nama"
                                                        id="wdp-form-nama-1a683af4" placeholder="Name" v-model="name"
                                                        required>
                                                </div>
                                                <input name="wdp-form-pesan" class="wdp-form-pesan"
                                                    id="wdp-form-pesan-1a683af4" type="hidden" value="hide">
                                                <div class="wdp-form-field-option wdp-option-type-radio">
                                                    <label for="wdp-form-option-1a683af4 wdp-form-option-1a683af4"
                                                        class=" " style="color: #d9b657;">
                                                        Confirmation </label>

                                                    <label class="to-select-option" style="color: #d9b657;">
                                                        <input type="radio" name="wdp-form-option wdp-form-option"
                                                            class="wdp-form-option wdp-form-option"
                                                            id="wdp-form-option-1a683af41"
                                                            value="Yes, I would love to attend" v-model="picked" required >
                                                        Yes, I would love to attend
                                                    </label>
                                                    <label class="to-select-option" style="color: #d9b657;"><input type="radio"
                                                            name="wdp-form-option wdp-form-option"
                                                            class="wdp-form-option wdp-form-option"
                                                            id="wdp-form-option-1a683af41 wdp-form-option-1a683af42"
                                                            value="Sorry, I will not able to attend" v-model="picked"
                                                            required >
                                                        Sorry, I will not able
                                                        to
                                                        attend
                                                    </label>
                                                </div>
                                                <div class="wdp-form-field-submit "
                                                    style="display: flex; justify-content: center;">
                                                    <a target="_blank"
                                                        :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya ' + name + ' ingin konfirmasi kehadiran undangan pernikahan bahwa ' + picked + '. Terima kasih'"
                                                        class="wdp-form-button"
                                                        style="width: 100%; display: flex; justify-content: center; color: #FFFFFF; font-size: 12px; font-weight: normal; background-color: #d19c47; border-style: solid; border-width: 1px 1px 1px 1px; border-color: #B6B6B6; border-radius: 25px 25px 25px 25px; padding: 5px 5px 5px 5px; justify-content: center">
                                                        Reservation By Whatsapp
                                                    </a>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <section v-if="config.gift_section !== 0"
                                class="elementor-section elementor-inner-section elementor-element elementor-element-3216b8a1 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="3216b8a1" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-537157f2 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="537157f2" data-element_type="column"
                                        data-settings="{&quot;animation_mobile&quot;:&quot;slideInUp&quot;,&quot;animation&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-3dd301f6 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                                data-id="3dd301f6" data-element_type="widget"
                                                data-widget_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-754c7e51 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="754c7e51" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">Wedding gift
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-643161a9 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-text-editor"
                                                data-id="643161a9" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container" style="color: #d9b657;">
                                                    <p>With all due respect,</p>
                                                    <p>For those of you who want to give a sign of love</p>
                                                    <p>For Bride and Groom, Can Via Virtual Account / E-Wallet</p>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1dfa0f6b elementor-align-center elementor-mobile-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="1dfa0f6b" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;rubberBand&quot;,&quot;_animation&quot;:&quot;pulse&quot;}"
                                                data-widget_type="button.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <!-- <a href="#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjE3NjgwIiwidG9nZ2xlIjpmYWxzZX0%3D"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            role="button">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-text">Send Gift</span>
                                                            </span>
                                                        </a> -->
                                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                            class="elementor-button-link elementor-button elementor-size-sm"
                                                            type="button" style="background-color: #d19c47;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-text">Amplop Digital</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-377b5b32 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="377b5b32" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #d9b657;">Please
                                                        confirm
                                                        Your gifts</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-68bb1c wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="68bb1c" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;rubberBand&quot;,&quot;_animation&quot;:&quot;pulse&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default"  style="color: #d9b657;"><a
                                                            :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya ingin mengkonfirmasi sudah mengirimkan angpao/gift wedding atas nama.... Terima kasih'" target="_blank">Click
                                                            Here</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="config.story_section !== 0"
                class="elementor-section elementor-top-section elementor-element elementor-element-bd30223 elementor-section-full_width elementor-section-height-full elementor-section-height-default elementor-section-items-middle wdp-sticky-section-no lws-sticky-section-no"
                data-id="bd30223" data-element_type="section" style="background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-420fbd4d wdp-sticky-section-no lws-sticky-section-no"
                        data-id="420fbd4d" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-5860e1a wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="5860e1a" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="elementor-element elementor-element-23535113 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="23535113" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="color: #1a1a1a;">Love Story</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-160d4de1 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="160d4de1" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;,&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default" style="color: #d19c47">Marriage is the golden ring
                                        in a
                                        chain whose beginning<br>is a glance and whose ending is eternity</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-48fa2629 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="48fa2629" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-4606266e elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="4606266e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div v-if="story.type1 !== undefined"
                                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-28580819 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="28580819" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5f5af77 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                                data-id="5f5af77" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="2560" height="1707"
                                                        v-if="story.type1.type == 'foto'"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media"
                                                        class="attachment-full size-full wp-image-17686" alt=""
                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                    <video v-if="story.type1.type == 'video'" width="100%" height="280px;"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media + '#t=0.001'"
                                                        type="video/mp4" controls preload="metadata"></video>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-3f29683 elementor-widget-divider--view-line_text elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                                data-id="3f29683" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                            <span
                                                                class="elementor-divider__text elementor-divider__element"  style="color: #1a1a1a;">
                                                                {{ story.title1.title }}​ </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4692461d wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-text-editor"
                                                data-id="4692461d" data-element_type="widget"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p v-html="story.description1.description"  style="color: #d19c47;"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="story.type2 !== undefined"
                                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-46cc8de6 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="46cc8de6" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;,&quot;animation_mobile&quot;:&quot;slideInUp&quot;,&quot;animation_delay&quot;:0}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-2c6c3bc8 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                                data-id="2c6c3bc8" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="2560" height="1707"
                                                        v-if="story.type2.type == 'foto'"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media"
                                                        class="attachment-full size-full wp-image-17683" alt=""
                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                    <video v-if="story.type2.type == 'video'" width="100%" height="280px;"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media + '#t=0.001'"
                                                        type="video/mp4" controls preload="metadata"></video>

                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2bdf7b91 elementor-widget-divider--view-line_text elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                                data-id="2bdf7b91" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                            <span
                                                                class="elementor-divider__text elementor-divider__element"  style="color: #1a1a1a;">
                                                                {{ story.title2.title }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-c3f9335 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-text-editor"
                                                data-id="c3f9335" data-element_type="widget"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p v-html="story.description2.description"  style="color: #d19c47;"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="story.type3 !== undefined"
                                        class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2b9b806e animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                                        data-id="2b9b806e" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;,&quot;animation_delay&quot;:0}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-684638d6 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                                data-id="684638d6" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="2560" height="1707"
                                                        v-if="story.type3.type == 'foto'"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media"
                                                        class="attachment-full size-full wp-image-17685" alt=""
                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                    <video v-if="story.type3.type == 'video'" width="100%" height="280px;"
                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media + '#t=0.001'"
                                                        type="video/mp4" controls preload="metadata"></video>

                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-568e0806 elementor-widget-divider--view-line_text elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                                data-id="568e0806" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                            <span
                                                                class="elementor-divider__text elementor-divider__element"  style="color: #1a1a1a;">
                                                                {{ story.title3.title }}​ </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-7aed1c03 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-text-editor"
                                                data-id="7aed1c03" data-element_type="widget"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p v-html="story.description3.description"  style="color: #d19c47;"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="elementor-element elementor-element-402f5578 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="402f5578" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-6a6e726f elementor-widget-divider--view-line_icon elementor-view-default elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                data-id="6a6e726f" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                                data-widget_type="divider.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator" style="color: #1a1a1a">
                                            <div class="elementor-icon elementor-divider__element">
                                                <i aria-hidden="true" class="fas fa-heart" style="color: #d19c47;"></i>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-7d399620 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="7d399620" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style="padding-bottom: 20px; background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default" style="display: flex; justify-content: center; align-items: center;">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-30cb8d wdp-sticky-section-no lws-sticky-section-no"
                        data-id="30cb8d" data-element_type="column" >
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-26759a63 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="26759a63" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7a4e35a2 wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-text-editor"
                                data-id="7a4e35a2" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">

                                    <p v-html="client.quote" style="color: #d19c47;"></p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-48320f17 elementor-widget-divider--view-line_text animated-slow elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                data-id="48320f17" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="divider.default">
                                <div class="elementor-widget-container">

                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator">
                                            <span class="elementor-divider__text elementor-divider__element" style="color: #1a1a1a;">
                                                {{ client.title_quote }} </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-648f7312 wdp-sticky-section-no lws-sticky-section-no"
                        data-id="648f7312" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-7ac68ee5 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                data-id="7ac68ee5" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;,&quot;_animation_mobile&quot;:&quot;fadeInUp&quot;}"
                                data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img decoding="async" width="2560" height="1707"
                                        :src="'https://admin.itsyourdayofficial.com/gallery/' + img1.file"
                                        class="attachment-full size-full wp-image-17695" alt="" loading="lazy"
                                        :srcset="`${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 2560w',
                                        ${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 300w',
                                        ${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 1024w',
                                        ${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 768w',
                                        ${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 1536w',
                                        ${'https://admin.itsyourdayofficial.com/gallery/' + img1.file} 2048w`" sizes="(max-width: 2560px) 100vw, 2560px" />
                               </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>
            <section v-if="config.invitation_section === 1" class="ftco-section" id="when-where-section" style="padding-bottom: 20px; background-color: #fbf9e7;">
                <div class="container">
                    <div class="elementor-element elementor-element-23535113 wdp-sticky-section-no lws-sticky-section-no "
                        data-id="23535113" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                            <h3 class="elementor-heading-title elementor-size-default" style="padding-top: 30px; color: #1a1a1a;">We cordially invite you</h3>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-12 my-5 text-center d-flex align-items-stretch">
                                    <div class="bride-groom ">
                                        <div class="row" style="padding-top: 50px;">

                                            <div class="col-md-6 col-sm-6 text-center" style="padding-top: 20px;">
                                                <h2 style="font-family: Great Vibes, cursive; color: #1a1a1a;">Keluarga Wanita</h2>
                                                <ul class="list-group">
                                                    <span v-for="invite in alsoInvite" :key="invite.id" style="color: #d19c47;">
                                                        <p v-if="invite.type === 'wanita'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                    </span>
                                                    
                                                </ul>
                                            </div>
                                            <div class="col-md-6 col-sm-6 text-center" style="padding-top: 20px;">
                                                <h2 style="font-family: Great Vibes, cursive;color: #1a1a1a;">Keluarga Pria</h2>
                                                <ul class="list-group">
                                                    <span v-for="invite in alsoInvite" :key="invite.id" style="color: #d19c47;">
                                                        <p v-if="invite.type === 'pria'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                    </span>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div  class="elementor-element elementor-element-6a6e726f elementor-widget-divider--view-line_icon elementor-view-default elementor-widget-divider--element-align-center wdp-sticky-section-no lws-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                        data-id="6a6e726f" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                        data-widget_type="divider.default">
                        <div class="elementor-widget-container">
                            <div class="elementor-divider">
                                <span class="elementor-divider-separator" style="color: #1a1a1a">
                                    <div class="elementor-icon elementor-divider__element" >
                                        <i aria-hidden="true" class="fas fa-heart" style="color:#d19c47"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-b9ce684 elementor-section-height-full elementor-section-items-bottom elementor-section-boxed elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="b9ce684" data-element_type="section"
                v-bind:style="{
                    'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/' + client.foto_cover + ')',
                    'background-repeat': 'no-repeat',
                    'transition-duration': '5000ms',
                    'background-size': 'cover',
                    'background-position': 'center top',
                    'transition-timing-function': 'ease-in-out',
                    'animation-duration': '5000ms',
                    'animation-name': 'zoom-out',
                    'animation-iteration-count': 'infinite',
                    'shape-outside': 'polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 4vw))',
                    'transform': 'scale(1)',
                    'transition': 'transform 2s ease-in-out'
                }">
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-47d08503 animated-slow wdp-sticky-section-no lws-sticky-section-no elementor-invisible"
                        data-id="47d08503" data-element_type="column"
                        data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-6396b636 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="6396b636" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-1aece889 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="1aece889" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5868aade wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="5868aade" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; color: #d9b657;">Join Our Wedding</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-3861844e wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="3861844e" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default"
                                        v-if="client.hajat == 'pria'" style="color: #d9b657;">
                                        {{ client.nama_pria }} &amp; {{ client.nama_wanita }}</h3>
                                    <h3 class="elementor-heading-title elementor-size-default"
                                        v-if="client.hajat == 'wanita'" style="color: #d9b657;">
                                        {{ client.nama_wanita }} &amp; {{ client.nama_pria }}</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2acd7653 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="2acd7653" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default" style="color: #d19c47;">{{ moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }} </h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-58e5c606 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="58e5c606" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-2b88b7bb elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="2b88b7bb" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style="background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-446b150e wdp-sticky-section-no lws-sticky-section-no"
                        data-id="446b150e" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-64875277 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="64875277" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-3f6190c1 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="3f6190c1" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default" style="font-size: 17px; color: #d19c47;">CREATE WITH LOVE BY</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-719dbc1b wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                data-id="719dbc1b" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <a href="https://itsyourdayofficial.com/" target="_blank" rel="noopener">
                                        <img decoding="async" width="5669" height="2835"
                                            src="assets/wp-content/uploads/2023/02/logo-iyd.png"
                                            class="attachment-full size-full wp-image-59" alt="" loading="lazy"
                                            srcset="assets/wp-content/uploads/2023/02/logo-iyd.png 5669w, assets/wp-content/uploads/2023/02/logo-iyd.png 300w, assets/wp-content/uploads/2023/02/logo-iyd.png 1024w, assets/wp-content/uploads/2023/02/logo-iyd.png 768w, assets/wp-content/uploads/2023/02/logo-iyd.png 1536w, assets/wp-content/uploads/2023/02/logo-iyd.png 2048w"
                                            sizes="(max-width: 5669px) 100vw, 5669px" /> </a>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-79c37cbf e-grid-align-tablet-center elementor-shape-rounded elementor-grid-0 e-grid-align-center wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-social-icons"
                                data-id="79c37cbf" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;none&quot;}"
                                data-widget_type="social-icons.default">
                                <div class="elementor-widget-container">

                                    <div class="elementor-social-icons-wrapper elementor-grid">
                                        <span class="elementor-grid-item" >
                                            <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-grow elementor-repeater-item-6154405"
                                                href="https://www.instagram.com/itsyourday_digitalinvitation/" target="_blank"
                                                rel="noopener" style="background-color: #fbf9e7;">
                                                <span class="elementor-screen-only">Instagram</span>
                                                <i class="fab fa-instagram" ></i> </a>
                                        </span>&ensp;
                                        <span class="elementor-grid-item">
                                            <a class="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-animation-grow elementor-repeater-item-1ac6089"
                                                href="https://wa.me/62822986192411?text=Hallo+Admin,+Saya+mau+order+undangan+digitalnya+nih?" target="_blank"
                                                rel="noopener" style="background-color: #fbf9e7;">
                                                <span class="elementor-screen-only">Whatsapp</span>
                                                <i class="fab fa-whatsapp"></i> </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-4ce1ba61 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="4ce1ba61" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style="background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7400e3ae wdp-sticky-section-no lws-sticky-section-no"
                        data-id="7400e3ae" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-49452df7 elementor-view-framed wdp-sticky-section-yes elementor-shape-circle wdp-sticky-section-positon-bottom elementor-widget elementor-widget-weddingpress-audio"
                                data-id="49452df7" data-element_type="widget" data-widget_type="weddingpress-audio.default">
                                <div class="elementor-widget-container">


                                    <div id="audio-container" class="audio-box">

                                        <!-- <audio id="song" loop>
                                            <source
                                                src="https://queenweddingstory.com/wp-content/uploads/2023/02/Spencer-Crandall-Youre-Still-The-One-Shania-Twain-Cover-128-kbps.mp3"
                                                type="audio/mp3">
                                        </audio> -->

                                        <audio :src="'https://admin.itsyourdayofficial.com/song/' + audio" ref="audioElm"
                                            loop="loop"></audio>



                                        <div style="z-index: 1000;" class="icon-bar">
                                            <button class="play" @click="play" v-if="playing" style="background: #d19c47; border: 1px solid #d19c47;"><i
                                                    class="fas fa-pause"></i></button>
                                            <button class="play" @click="play" v-else style="background: #d19c47; border: 1px solid #d19c47;"><i class="fas fa-play" ></i></button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-79234a85 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="79234a85" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style="background-color: #fbf9e7;">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3ff52bc6 wdp-sticky-section-no lws-sticky-section-no"
                        data-id="3ff52bc6" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-10f7c485 elementor-widget__width-inherit elementor-widget-mobile__width-inherit wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-landingstarwedding-wellcome"
                                data-id="10f7c485" data-element_type="widget"
                                data-widget_type="landingstarwedding-wellcome.default">
                                <div class="elementor-widget-container">
                                    <Modal v-show="isModalVisible" @close="closeModal" :client='client' :guest='guest' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <center><h5 class="modal-title" id="exampleModalLongTitle" style="font-family: Great Vibes, cursive;color: #1a1a1a;font-weight: 700;font-size: 25px !important;text-align: center;">Kirim Hadiah Untuk Mempelai</h5></center>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12" style="border:2px solid rgb(217, 182, 87); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                            <p class="card-text" v-if="client.bank == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc" class="form-control" id="myInput" readonly style="border: 1px solid black;">
                            <div class="input-group-append">
                                <button data-target="#collapseExample"  class="btn btn-outline-secondary" type="button" @click.prevent="myFunction" id="button-addon2">Copy</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank2 != null" class="col-12" style="border:2px solid rgb(217, 182, 87); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank2 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                            <p class="card-text" v-if="client.bank2 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank2}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc2" class="form-control" id="myInput2" readonly style="border: 1px solid black;">
                            <div class="input-group-append">
                                <button data-target="#collapseExample2" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction2" id="button-addon2">Copy</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank3 != null" class="col-12" style="border:2px solid rgb(217, 182, 87); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank3 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                            <p class="card-text" v-if="client.bank3 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank3}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc3" class="form-control" id="myInput3" readonly style="border: 1px solid black;">
                            <div class="input-group-append">
                                <button data-target="#collapseExample3" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction3" id="button-addon2">Copy</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="client.bank4 != null" class="col-12" style="border:2px solid rgb(217, 182, 87); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <p class="card-text" v-if="client.bank4 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                            <p class="card-text" v-if="client.bank4 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"> <br> A.n {{client.acc_bank4}}</p>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3">
                            <input type="text" v-model="client.no_acc4" class="form-control" id="myInput4" readonly style="border: 1px solid black;">
                            <div class="input-group-append">
                                <button data-target="#collapseExample4" class="btn btn-outline-secondary" type="button" @click.prevent="myFunction4" id="button-addon2">Copy</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div v-if="client.gift_address != null" class="col-12" style="border:2px solid rgb(217, 182, 87); margin-top: 10px;">
                        <div class="col-12 mx-auto mt-3 mb-3 text-center"> 
                            <h3>PHYSICAL GIFTS ADDRESS</h3>
                        </div>
                        <div class="input-group col-12 mx-auto mb-3" style="padding: 15px;">
                            <p>{{client.gift_address}}</p>
                        </div>
                    </div>
                    
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
                </div>
            </div>
        </div>
        <div data-elementor-type="popup" data-elementor-id="17680" class="elementor elementor-17680 elementor-location-popup"
            data-elementor-settings="{&quot;entrance_animation&quot;:&quot;slideInLeft&quot;,&quot;entrance_animation_mobile&quot;:&quot;slideInRight&quot;,&quot;exit_animation&quot;:&quot;slideInRight&quot;,&quot;exit_animation_mobile&quot;:&quot;slideInRight&quot;,&quot;entrance_animation_duration&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;timing&quot;:[]}">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-3954e947 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="3954e947" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3262b3af wdp-sticky-section-no lws-sticky-section-no"
                        data-id="3262b3af" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-67cca4c0 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="67cca4c0" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-4f645724 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="4f645724" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">WEDDING GIFT</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-431a5c28 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="431a5c28" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">Please transfer the prize via
                                        the following<br> account number or digital wallet</h3>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-40a45ebf elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="40a45ebf" data-element_type="section"
                                data-settings="{&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-270c71c9 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="270c71c9" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-6b2b6f21 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                                data-id="6b2b6f21" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img width="1526" height="474"
                                                        src="assets/wp-content/uploads/2023/02/342-3424868_bca-png.png"
                                                        class="attachment-full size-full wp-image-435" alt="" loading="lazy"
                                                        srcset="assets/wp-content/uploads/2023/02/342-3424868_bca-png.png 1526w, assets/wp-content/uploads/2023/02/342-3424868_bca-png-300x93.png 300w, assets/wp-content/uploads/2023/02/342-3424868_bca-png-1024x318.png 1024w, assets/wp-content/uploads/2023/02/342-3424868_bca-png-768x239.png 768w"
                                                        sizes="(max-width: 1526px) 100vw, 1526px" />
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4eb2ef95 elementor-widget-divider--view-line wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                                data-id="4eb2ef95" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2cd32a13 elementor-mobile-align-left wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-weddingpress-modal-popup"
                                                data-id="2cd32a13" data-element_type="widget"
                                                data-widget_type="weddingpress-modal-popup.default">
                                                <div class="elementor-widget-container">
                                                    <div class="exad-modal">
                                                        <div class="exad-modal-wrapper">
                                                            <div class="elementor-button-wrapper"><a href="#"
                                                                    class="exad-modal-image-action image-modal"
                                                                    data-exad-modal="#exad-modal-2cd32a13"
                                                                    data-exad-overlay="yes"><span class="elementor-button"
                                                                        role="button"><span
                                                                            class="elementor-button-content-wrapper"><i
                                                                                aria-hidden="true"
                                                                                class="fas fa-qrcode"></i><span
                                                                                style="padding-left:5px"></span></span></span></a>
                                                            </div>
                                                            <div id="exad-modal-2cd32a13"
                                                                class="exad-modal-item modal-vimeo zoom-in image">
                                                                <div class="
                                            exad-modal-content">
                                                                    <div class="exad-modal-element "><img src="" />
                                                                        <div class="exad-close-btn"><span></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="exad-modal-overlay" data-exad_overlay_click_close="yes">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4926741 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                                data-id="4926741" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default">qrcode</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-50d3082a elementor-align-right elementor-mobile-align-right wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-weddingpress-copy-text"
                                                data-id="50d3082a" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="weddingpress-copy-text.default">
                                                <div class="elementor-widget-container">

                                                    <div class="elementor-image img"></div>

                                                    <div class="head-title"></div>
                                                    <div class="elementor-button-wrapper">
                                                        <div class="copy-content spancontent" style="display: none;">
                                                            7772155657
                                                        </div>

                                                        <a style="cursor:pointer;" onclick="copyText(this)"
                                                            data-message="copy sucsess" class="elementor-button" role="button">

                                                            <div class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="far fa-copy"></i> </span>
                                                                <span class="elementor-button-text">7772155657</span>
                                                            </div>
                                                        </a>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-18ace3cf wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                                data-id="18ace3cf" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default">Yuni Eva
                                                        Kartika
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-5bf434fb elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                                data-id="5bf434fb" data-element_type="section"
                                data-settings="{&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1aa6f484 wdp-sticky-section-no lws-sticky-section-no"
                                        data-id="1aa6f484" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation_mobile&quot;:&quot;slideInUp&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5f54d2f6 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-image"
                                                data-id="5f54d2f6" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img width="2560" height="745"
                                                        src="assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg.png"
                                                        class="attachment-full size-full wp-image-1357" alt="" loading="lazy"
                                                        srcset="assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg.png 2560w, assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg-300x87.png 300w, assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg-1024x298.png 1024w, assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg-768x224.png 768w, assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg-1536x447.png 1536w, assets/wp-content/uploads/2023/02/Bank_Mandiri_logo_2016.svg-2048x596.png 2048w"
                                                        sizes="(max-width: 2560px) 100vw, 2560px" />
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-5a600409 elementor-widget-divider--view-line wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                                data-id="5a600409" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1e9ea705 elementor-mobile-align-left wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-weddingpress-modal-popup"
                                                data-id="1e9ea705" data-element_type="widget"
                                                data-widget_type="weddingpress-modal-popup.default">
                                                <div class="elementor-widget-container">
                                                    <div class="exad-modal">
                                                        <div class="exad-modal-wrapper">
                                                            <div class="elementor-button-wrapper"><a href="#"
                                                                    class="exad-modal-image-action image-modal"
                                                                    data-exad-modal="#exad-modal-1e9ea705"
                                                                    data-exad-overlay="yes"><span class="elementor-button"
                                                                        role="button"><span
                                                                            class="elementor-button-content-wrapper"><i
                                                                                aria-hidden="true"
                                                                                class="fas fa-qrcode"></i><span
                                                                                style="padding-left:5px"></span></span></span></a>
                                                            </div>
                                                            <div id="exad-modal-1e9ea705"
                                                                class="exad-modal-item modal-vimeo zoom-in image">
                                                                <div class="
                                    exad-modal-content">
                                                                    <div class="exad-modal-element "><img src="" />
                                                                        <div class="exad-close-btn"><span></span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="exad-modal-overlay" data-exad_overlay_click_close="yes">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-10a2e38d wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                                data-id="10a2e38d" data-element_type="widget"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default">qrcode</h3>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-5230c6b0 elementor-align-right elementor-mobile-align-right wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-weddingpress-copy-text"
                                                data-id="5230c6b0" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="weddingpress-copy-text.default">
                                                <div class="elementor-widget-container">

                                                    <div class="elementor-image img"></div>

                                                    <div class="head-title"></div>
                                                    <div class="elementor-button-wrapper">
                                                        <div class="copy-content spancontent" style="display: none;">
                                                            1570006807557</div>

                                                        <a style="cursor:pointer;" onclick="copyText(this)"
                                                            data-message="copy sucsess" class="elementor-button" role="button">

                                                            <div class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon elementor-align-icon-left">
                                                                    <i aria-hidden="true" class="far fa-copy"></i> </span>
                                                                <span class="elementor-button-text">1570006807557</span>
                                                            </div>
                                                        </a>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-4240cd5 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                                data-id="4240cd5" data-element_type="widget"
                                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-heading-title elementor-size-default">Muhammad Nur
                                                        Badruddin</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="elementor-element elementor-element-15a11a3f wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="15a11a3f" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-6c91dfe0 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="6c91dfe0" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">PHYSICAL GIFTS ADDRESS</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-69bbc3da elementor-widget-divider--view-line wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-divider"
                                data-id="69bbc3da" data-element_type="widget" data-widget_type="divider.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-divider">
                                        <span class="elementor-divider-separator">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-29f2b4b7 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="29f2b4b7" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">Muhammad Nur Badruddin<br>
                                        +6285861570425<br>
                                        Jl Fatmawati No 157, Rt11/RW01, Lopait, Kec. Tuntang. (Sebelah Warung Makan Bu
                                        Lilik) Kab. Semarang
                                        Jawa
                                        Tengah 50773
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div data-elementor-type="popup" data-elementor-id="17681" class="elementor elementor-17681 elementor-location-popup"
            data-elementor-settings="{&quot;entrance_animation&quot;:&quot;slideInLeft&quot;,&quot;entrance_animation_mobile&quot;:&quot;slideInRight&quot;,&quot;exit_animation&quot;:&quot;slideInRight&quot;,&quot;exit_animation_mobile&quot;:&quot;slideInRight&quot;,&quot;entrance_animation_duration&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;timing&quot;:[]}">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f3f937f elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no lws-sticky-section-no"
                data-id="f3f937f" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-407404d2 wdp-sticky-section-no lws-sticky-section-no"
                        data-id="407404d2" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-3dd582d2 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="3dd582d2" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-62cda416 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="62cda416" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">Gift Confirmation </h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-3cf0ef45 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-heading"
                                data-id="3cf0ef45" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h3 class="elementor-heading-title elementor-size-default">Confirm your shipment to make
                                        it easier for<br> the bride and groom to collect data</h3>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-345006c1 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-weddingpress-forms"
                                data-id="345006c1" data-element_type="widget"
                                data-settings="{&quot;_animation_mobile&quot;:&quot;zoomIn&quot;}"
                                data-widget_type="weddingpress-forms.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-wdp-form-wrapper elementor-wdp-form-button-align-fullwidth">
                                        <form method="get" class="wdp-form wdp-wa-form" id="wdp-wa-form-345006c1"
                                            data-waapi="https://api.whatsapp.com/send?phone=6282216611695&amp;text=Hai%2C%20saya%20%2A%25nama%25%2A%20ingin%20konfirmasi%0AMemberikan%20Amplop%20Digital%0ABerupa%20%25option%25%20%25jumlah%25%0A%0A_%2A%25pesan%25%2A_.%0ATerima%20kasih.">
                                            <div class="wdp-form-fields-wrapper">
                                                <div class="wdp-form-field-nama">
                                                    <label for="wdp-form-nama-345006c1" class="elementor-screen-only">
                                                        Name </label>
                                                    <input type="text" name="wdp-form-nama" class="wdp-form-nama"
                                                        id="wdp-form-nama-345006c1" placeholder="Name" value="">
                                                </div>
                                                <div class="wdp-form-field-jumlah">
                                                    <label for="wdp-form-jumlah-345006c1" class="elementor-screen-only">
                                                        Jumlah </label>
                                                    <input name="wdp-form-jumlah" class="wdp-form-jumlah"
                                                        id="wdp-form-jumlah-345006c1" type="text" placeholder="Amount" value="">
                                                </div>
                                                <div class="wdp-form-field-pesan">
                                                    <label for="wdp-form-pesan-345006c1" class="elementor-screen-only">
                                                        Message </label>
                                                    <textarea name="wdp-form-pesan" class="wdp-form-pesan"
                                                        id="wdp-form-pesan-345006c1" rows="4" placeholder="Message"></textarea>
                                                </div>
                                                <div class="wdp-form-field-option wdp-option-type-radio">
                                                    <label for="wdp-form-option-345006c1 wdp-form-option-345006c1" class=" ">
                                                        Confirmation </label>

                                                    <label class="to-select-option"><input type="radio"
                                                            name="wdp-form-option wdp-form-option"
                                                            class="wdp-form-option wdp-form-option"
                                                            id="wdp-form-option-345006c11" value="Money Of Tranfer"> Money
                                                        Of Tranfer</label>
                                                    <label class="to-select-option"><input type="radio"
                                                            name="wdp-form-option wdp-form-option"
                                                            class="wdp-form-option wdp-form-option"
                                                            id="wdp-form-option-345006c11 wdp-form-option-345006c12"
                                                            value="Send Gift"> Send
                                                        Gift</label>
                                                </div>
                                                <div class="wdp-form-field-submit">
                                                    <button type="submit" class="wdp-form-button">
                                                        Send Confirmation </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                            <div class="elementor-element elementor-element-50c11858 wdp-sticky-section-no lws-sticky-section-no elementor-widget elementor-widget-spacer"
                                data-id="50c11858" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Modal from '../components/Modal.vue';
export default {
    name: 'home',
    components: {
        Modal,
    },
    data() {
        return {
            client: {},
            guest: {},
            gallery: [],
            img1: '',
            img2: '',
            img3: '',
            img4: '',
            moment: moment,
            isModalVisible: true,
            playing: true,
            audio: '',
            errors: {},
            date: '',
            now: 0,
            count: 0,
            config: {},
            story: {
                title1: '',
                description1: '',
                date1: '',
                month1: '',
                year1: '',
                media1: '',
                type1: '',
                order1: '',
                title2: '',
                description2: '',
                date2: '',
                month2: '',
                year2: '',
                media2: '',
                type2: '',
                order2: '',
                title3: '',
                description3: '',
                date3: '',
                month3: '',
                year3: '',
                media3: '',
                type3: '',
                order3: '',
                title4: '',
                description4: '',
                date4: '',
                month4: '',
                year4: '',
                media4: '',
                type4: '',
                order4: '',
                title5: '',
                description5: '',
                date5: '',
                month5: '',
                year5: '',
                media5: '',
                type5: '',
                order5: '',
            },
            name: '',
            picked: '',
            submit: {
                client_id: '',
                name: '',
                comment: '',
                confirmation: '',
            },
            hopes: {},
            hasPrev: false,
            hasNext: true,
            alsoInvite: {},
        }
    },
    created() {
        if (this.isModalVisible == true) {
            document.body.style.overflow = "hidden";
            document.body.style.position = "fixed";
        }
    },
    mounted() {
        
        let toParam = this.$route.query.to;
        if (toParam) {
            toParam = decodeURIComponent(toParam);
        if (toParam.includes('-')) {
            // use original parameter value
        } else {
            // use empty string as parameter value
            toParam = '';
        }
        } else {
            toParam = '';
        }
        this.$api.get('/' + this.$route.params.slug, {
            params: {
                to: toParam,
                template: window.location.origin + "/"
            }
        })
        .then(response => {
            if (!response.data.data) {
                this.$router.push({ name: 'PageNotFound' });
                return;
            }
            var currentUrl = window.location.href;
            this.client = response.data.data;
            document.title = "Wedding Invitation " + response.data.data.nama_wanita + " & " + response.data.data.nama_pria + " by It'sYourDay";
            this.gallery = response.data.data.gallery;
            this.pages = this.gallery.length;
            this.img1 = response.data.data.gallery[0];
            this.img2 = response.data.data.gallery[1];
            this.img3 = response.data.data.gallery[2];
            this.img4 = response.data.data.gallery[3];
            if (currentUrl.includes(this.client.template) == false) {
                this.$router.push({ name: 'PageNotFound' });
            }
            this.guest = response.data.data;
            this.audio = response.data.data.song;
            this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
            this.config = response.data.data.config[0];
            this.story.title1 = response.data.data.story[0];
            this.story.description1 = response.data.data.story[0];
            this.story.date1 = response.data.data.story[0];
            this.story.month1 = response.data.data.story[0];
            this.story.year1 = response.data.data.story[0];
            this.story.media1 = response.data.data.story[0];
            this.story.type1 = response.data.data.story[0];
            this.story.order1 = response.data.data.story[0];
            this.story.title2 = response.data.data.story[1];
            this.story.description2 = response.data.data.story[1];
            this.story.date2 = response.data.data.story[1];
            this.story.month2 = response.data.data.story[1];
            this.story.year2 = response.data.data.story[1];
            this.story.media2 = response.data.data.story[1];
            this.story.type2 = response.data.data.story[1];
            this.story.order2 = response.data.data.story[1];
            this.story.title3 = response.data.data.story[2];
            this.story.description3 = response.data.data.story[2];
            this.story.date3 = response.data.data.story[2];
            this.story.month3 = response.data.data.story[2];
            this.story.year3 = response.data.data.story[2];
            this.story.media3 = response.data.data.story[2];
            this.story.type3 = response.data.data.story[2];
            this.story.order3 = response.data.data.story[2];
            this.story.title4 = response.data.data.story[3];
            this.story.description4 = response.data.data.story[3];
            this.story.date4 = response.data.data.story[3];
            this.story.month4 = response.data.data.story[3];
            this.story.year4 = response.data.data.story[3];
            this.story.media4 = response.data.data.story[3];
            this.story.type4 = response.data.data.story[3];
            this.story.order4 = response.data.data.story[3];
            this.story.title5 = response.data.data.story[4];
            this.story.description5 = response.data.data.story[4];
            this.story.date5 = response.data.data.story[4];
            this.story.month5 = response.data.data.story[4];
            this.story.year5 = response.data.data.story[4];
            this.story.media5 = response.data.data.story[4];
            this.story.type5 = response.data.data.story[4];
            this.story.order5 = response.data.data.story[4];
            this.submit.client_id = this.client.id;
            // Update hasNext property based on whether gallery has items
            this.hasNext = this.gallery.length > 1;
            this.alsoInvite = response.data.data.also_invite;
            this.timer_loop();
            this.modifiedDate = Math.trunc(Date.parse(this.date) / 1000);
            this.$api.get('comment/' + this.client.id)
                .then(response => {
                    this.hopes = response.data.data;
                })
        })
        .catch(error => {
            // this.$router.push({ name: 'PageNotFound' });
            console.log(error.response.data);
        });

        this.$refs.horizontal.scrollToIndex(1);
    },
    methods: {
        closeModal() {
            this.isModalVisible = false;
            var a = this.$refs.audioElm;
            a.play();
            this.playing = true;

            if (this.isModalVisible == false) {
                document.body.style.position = null;
                document.body.style.overflow = "auto";
            }
        },
        play: function (event) {
            var a = this.$refs.audioElm;
            if (a.paused) {
                a.play();
                this.playing = true;
            } else {
                a.pause();
                this.playing = false;
            }
        },
        timer_loop() {
            this.count++;
            this.now = Math.trunc(new Date().getTime() / 1000);
            if (this.count < 200 && this.modifiedDate > this.now) {
            setTimeout(this.timer_loop, 1000);
            } else {
            this.count = 0;
            }
        },
        post() {
            this.$api.post('comment', this.submit)
                .then(response => {
                    this.success = response.data.success
                    this.submit.name = '';
                    this.submit.comment = '';
                    this.submit.confirmation = '';
                    this.$api.get('comment/' + this.client.id)
                        .then(response => {
                            this.hopes = response.data.data;
                        })
                    console.log(response)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        prev() {
            this.$refs.horizontal.prev()
        },
        next() {
            this.$refs.horizontal.next()
        },
        onScroll({ hasPrev, hasNext }) {
            this.hasPrev = hasPrev
            this.hasNext = hasNext
        },

        myFunction() {
            /* Get the text field */
            var copyText = document.getElementById("myInput");
            const button = document.body.querySelector('[data-target="#collapseExample"]')
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices *
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value)
            if(button.innerText.toLowerCase() === 'copy') {
                button.innerText = 'Copied';
            }
        },

        myFunction2() {
            /* Get the text field */
            var copyText = document.getElementById("myInput2");
            const button = document.body.querySelector('[data-target="#collapseExample2"]')
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices *
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value)
            if(button.innerText.toLowerCase() === 'copy') {
                button.innerText = 'Copied';
            }
        },

        myFunction3() {
            /* Get the text field */
            var copyText = document.getElementById("myInput3");
            const button = document.body.querySelector('[data-target="#collapseExample3"]')
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices *
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value)
            if(button.innerText.toLowerCase() === 'copy') {
                button.innerText = 'Copied';
            }
        },

        myFunction4() {
            /* Get the text field */
            var copyText = document.getElementById("myInput4");
            const button = document.body.querySelector('[data-target="#collapseExample4"]')
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices *
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value)
            if(button.innerText.toLowerCase() === 'copy') {
                button.innerText = 'Copied';
            }
        },
    },
    computed: {
        seconds() {
            const result = (this.modifiedDate - this.now) % 60;
            return result < 0 ? 0 : result;
        },
        minutes() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60) % 60;
            return result < 0 ? 0 : result;
        },
        hours() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
            return result < 0 ? 0 : result;
        },
        days() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
            return result < 0 ? 0 : result;
        },
        modifiedDate: function () {
            return Math.trunc(Date.parse(this.date) / 1000)
        }
    },
    watch: {
        gallery() {
            this.pages = this.gallery.length;
        }
    }
}
</script>



<style scoped>
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
.bride-groom {
  margin-top: -5rem;
  width: 100%;
  position: relative;
  z-index: 0; }
  .bride-groom:before {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: -40px;
    content: '';
    border: 1px solid #d9b657;
    z-index: -1;
    -webkit-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    transform: rotate(-1deg); }
  .bride-groom:after {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: -40px;
    content: '';
    border: 1px solid #d9b657;
    z-index: -1;
    -webkit-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    transform: rotate(1deg); }
  .bride-groom .img {
    display: block;
    width: 250px;
    height: 250px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    margin: 0 auto; 
    /* border: 2px solid #915310; */
  }
  .bride-groom .text h3 {
    font-size: 22px; }
    .bride-groom .text h2 {
      font-size: 30px;
    /* padding-top: 3rem;  */
  }
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.item {
    width: 240px;
    /* padding: 32px 32px 24px 32px;
  background: #f1f1f1; */
    margin-right: 20px;
    display: flex;
    align-items: center;
    /* added */
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.gallery {
    width: 100%;
    /* height: 450px; */
    display: block;
    margin-bottom: 30px;
    position: relative;
    border-radius: 20px;
    z-index: 0;
}

.gallery:after {
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d9b657;
    opacity: 0;
    content: '';
    z-index: -1;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.gallery .icon {
    width: 70px;
    height: 70px;
    background: #fff;
    opacity: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.gallery:hover:after,
.gallery:focus:after {
    opacity: .8;
}

.gallery:hover .icon,
.gallery:focus .icon {
    opacity: 1;
}
</style>

<!-- Pagination CSS -->
<style scoped>
header .pagination {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav>button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    margin: 0.25rem;
}

nav>button.active {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #d19c47;
}

nav>button.inactive {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #d9b657;
}

nav>button:focus {
    outline: none;
}

nav>button>svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: currentColor;
}
</style>
<style scoped>
@keyframes zoom-out {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.icon-bar button {
    /* display: block; */
    /* text-align: center; */
    padding: 8px;
    transition: all 0.3s ease;
    color: #ffffff;
    height: 40px;
    width: 40px;
    font-size: 15px;
    background: #162447;
    border-radius: 50%;
    border: 1px solid #162447;
}

/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-column .elementor-spacer-inner {
    height: var(--spacer-size)
}

.e-con {
    --container-widget-width: 100%
}

.e-con-inner>.elementor-widget-spacer,
.e-con>.elementor-widget-spacer {
    width: var(--container-widget-width, var(--spacer-size));
    --align-self: var(--container-widget-align-self, initial);
    --flex-shrink: 0
}

.e-con-inner>.elementor-widget-spacer>.elementor-widget-container,
.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer,
.e-con>.elementor-widget-spacer>.elementor-widget-container,
.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer {
    height: 100%
}

.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner,
.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner {
    height: var(--container-widget-height, var(--spacer-size))
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size: 59px
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
    background-color: #818a91;
    color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
    color: #818a91;
    border: 3px solid;
    background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
    margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
    width: 1em;
    height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
    float: left;
    text-align: center;
    line-height: 1;
    font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
    display: inline-block
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #2c2c2c;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
    display: flex
}

.elementor-widget-divider .elementor-divider__text {
    font-size: 15px;
    line-height: 1;
    max-width: 95%
}

.elementor-widget-divider .elementor-divider__element {
    margin: 0 var(--divider-element-spacing);
    flex-shrink: 0
}

.elementor-widget-divider .elementor-icon {
    font-size: var(--divider-icon-size)
}

.elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator,
.elementor-widget-divider--view-line_text .elementor-divider-separator {
    align-items: center
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
.elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
.elementor-widget-divider--view-line_text .elementor-divider-separator:after,
.elementor-widget-divider--view-line_text .elementor-divider-separator:before {
    display: block;
    content: "";
    border-bottom: 0;
    flex-grow: 1;
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-left .elementor-divider-separator:before {
    content: none
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
    margin-left: 0
}

.elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
    flex-grow: 0;
    flex-shrink: 100
}

.elementor-widget-divider--element-align-right .elementor-divider-separator:after {
    content: none
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
    margin-right: 0
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
    --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
.elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
    width: 100%;
    min-height: var(--divider-pattern-height);
    -webkit-mask-size: var(--divider-pattern-size) 100%;
    mask-size: var(--divider-pattern-size) 100%;
    -webkit-mask-repeat: var(--divider-pattern-repeat);
    mask-repeat: var(--divider-pattern-repeat);
    background-color: var(--divider-color);
    -webkit-mask-image: var(--divider-pattern-url);
    mask-image: var(--divider-pattern-url)
}

.elementor-widget-divider--no-spacing {
    --divider-pattern-size: auto
}

.elementor-widget-divider--bg-round {
    --divider-pattern-repeat: round
}

.rtl .elementor-widget-divider .elementor-divider__text {
    direction: rtl
}

.e-con-inner>.elementor-widget-divider,
.e-con>.elementor-widget-divider {
    width: var(--container-widget-width, 100%);
    --flex-grow: var(--container-widget-flex-grow)
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-widget-image {
    text-align: center
}

.elementor-widget-image a {
    display: inline-block
}

.elementor-widget-image a img[src$=".svg"] {
    width: 48px
}

.elementor-widget-image img {
    vertical-align: middle;
    display: inline-block
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-widget-image-carousel .swiper,
.elementor-widget-image-carousel .swiper-container {
    position: static
}

.elementor-widget-image-carousel .swiper-container .swiper-slide figure,
.elementor-widget-image-carousel .swiper .swiper-slide figure {
    line-height: inherit
}

.elementor-widget-image-carousel .swiper-slide {
    text-align: center
}

.elementor-image-carousel-wrapper:not(.swiper-container-initialized) .swiper-slide,
.elementor-image-carousel-wrapper:not(.swiper-initialized) .swiper-slide {
    max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3))
}
</style>

<style scoped>
/*! pro-elements - v3.8.0 - 30-10-2022 */
.elementor-gallery__container {
    min-height: 1px
}

.elementor-gallery-item {
    position: relative;
    overflow: hidden;
    display: block;
    text-decoration: none;
    border: solid var(--image-border-width) var(--image-border-color);
    border-radius: var(--image-border-radius)
}

.elementor-gallery-item__content,
.elementor-gallery-item__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.elementor-gallery-item__overlay {
    mix-blend-mode: var(--overlay-mix-blend-mode);
    -webkit-transition-duration: var(--overlay-transition-duration);
    -o-transition-duration: var(--overlay-transition-duration);
    transition-duration: var(--overlay-transition-duration);
    -webkit-transition-property: mix-blend-mode, opacity, background-color, -webkit-transform;
    transition-property: mix-blend-mode, opacity, background-color, -webkit-transform;
    -o-transition-property: mix-blend-mode, transform, opacity, background-color;
    transition-property: mix-blend-mode, transform, opacity, background-color;
    transition-property: mix-blend-mode, transform, opacity, background-color, -webkit-transform
}

.elementor-gallery-item__image.e-gallery-image {
    -webkit-transition-duration: var(--image-transition-duration);
    -o-transition-duration: var(--image-transition-duration);
    transition-duration: var(--image-transition-duration);
    -webkit-transition-property: -webkit-filter, -webkit-transform;
    transition-property: -webkit-filter, -webkit-transform;
    -o-transition-property: filter, transform;
    transition-property: filter, transform;
    transition-property: filter, transform, -webkit-filter, -webkit-transform
}

.elementor-gallery-item__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: var(--content-justify-content, center);
    -ms-flex-pack: var(--content-justify-content, center);
    justify-content: var(--content-justify-content, center);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: var(--content-text-align);
    padding: var(--content-padding)
}

.elementor-gallery-item__content>div {
    -webkit-transition-duration: var(--content-transition-duration);
    -o-transition-duration: var(--content-transition-duration);
    transition-duration: var(--content-transition-duration)
}

.elementor-gallery-item__content.elementor-gallery--sequenced-animation>div:nth-child(2) {
    -webkit-transition-delay: calc(var(--content-transition-delay) / 3);
    -o-transition-delay: calc(var(--content-transition-delay) / 3);
    transition-delay: calc(var(--content-transition-delay) / 3)
}

.elementor-gallery-item__content.elementor-gallery--sequenced-animation>div:nth-child(3) {
    -webkit-transition-delay: calc(var(--content-transition-delay) / 3 * 2);
    -o-transition-delay: calc(var(--content-transition-delay) / 3 * 2);
    transition-delay: calc(var(--content-transition-delay) / 3 * 2)
}

.elementor-gallery-item__content.elementor-gallery--sequenced-animation>div:nth-child(4) {
    -webkit-transition-delay: calc(var(--content-transition-delay) / 3 * 3);
    -o-transition-delay: calc(var(--content-transition-delay) / 3 * 3);
    transition-delay: calc(var(--content-transition-delay) / 3 * 3)
}

.elementor-gallery-item__description {
    color: var(--description-text-color, #fff);
    width: 100%
}

.elementor-gallery-item__title {
    color: var(--title-text-color, #fff);
    font-weight: 700;
    width: 100%
}

.elementor-gallery__titles-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: var(--titles-container-justify-content, center);
    -ms-flex-pack: var(--titles-container-justify-content, center);
    justify-content: var(--titles-container-justify-content, center);
    margin-bottom: 20px
}

.elementor-gallery__titles-container:not(.e--pointer-framed) .elementor-item:after,
.elementor-gallery__titles-container:not(.e--pointer-framed) .elementor-item:before {
    background-color: var(--galleries-pointer-bg-color-hover)
}

.elementor-gallery__titles-container:not(.e--pointer-framed) .elementor-item.elementor-item-active:after,
.elementor-gallery__titles-container:not(.e--pointer-framed) .elementor-item.elementor-item-active:before {
    background-color: var(--galleries-pointer-bg-color-active)
}

.elementor-gallery__titles-container.e--pointer-framed .elementor-item:before {
    border-color: var(--galleries-pointer-bg-color-hover);
    border-width: var(--galleries-pointer-border-width)
}

.elementor-gallery__titles-container.e--pointer-framed .elementor-item:after {
    border-color: var(--galleries-pointer-bg-color-hover)
}

.elementor-gallery__titles-container.e--pointer-framed .elementor-item.elementor-item-active:after,
.elementor-gallery__titles-container.e--pointer-framed .elementor-item.elementor-item-active:before {
    border-color: var(--galleries-pointer-bg-color-active)
}

.elementor-gallery__titles-container.e--pointer-framed.e--animation-draw .elementor-item:before {
    border-left-width: var(--galleries-pointer-border-width);
    border-bottom-width: var(--galleries-pointer-border-width);
    border-right-width: 0;
    border-top-width: 0
}

.elementor-gallery__titles-container.e--pointer-framed.e--animation-draw .elementor-item:after {
    border-left-width: 0;
    border-bottom-width: 0;
    border-right-width: var(--galleries-pointer-border-width);
    border-top-width: var(--galleries-pointer-border-width)
}

.elementor-gallery__titles-container.e--pointer-framed.e--animation-corners .elementor-item:before {
    border-left-width: var(--galleries-pointer-border-width);
    border-bottom-width: 0;
    border-right-width: 0;
    border-top-width: var(--galleries-pointer-border-width)
}

.elementor-gallery__titles-container.e--pointer-framed.e--animation-corners .elementor-item:after {
    border-left-width: 0;
    border-bottom-width: var(--galleries-pointer-border-width);
    border-right-width: var(--galleries-pointer-border-width);
    border-top-width: 0
}

.elementor-gallery__titles-container .e--pointer-double-line .elementor-item:after,
.elementor-gallery__titles-container .e--pointer-double-line .elementor-item:before,
.elementor-gallery__titles-container .e--pointer-overline .elementor-item:before,
.elementor-gallery__titles-container .e--pointer-underline .elementor-item:after {
    height: var(--galleries-pointer-border-width)
}

.elementor-gallery-title {
    --space-between: 10px;
    cursor: pointer;
    color: #6d7882;
    font-weight: 500;
    position: relative;
    padding: 7px 14px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}

.elementor-gallery-title--active {
    color: #495157
}

.elementor-gallery-title:not(:last-child) {
    margin-right: var(--space-between)
}

.elementor-gallery-item__title+.elementor-gallery-item__description {
    margin-top: var(--description-margin-top)
}

.e-gallery-item.elementor-gallery-item {
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all
}

.e-gallery-item.elementor-animated-content .elementor-animated-item--enter-from-bottom,
.e-gallery-item.elementor-animated-content .elementor-animated-item--enter-from-left,
.e-gallery-item.elementor-animated-content .elementor-animated-item--enter-from-right,
.e-gallery-item.elementor-animated-content .elementor-animated-item--enter-from-top,
.e-gallery-item:hover .elementor-gallery__item-overlay-bg,
.e-gallery-item:hover .elementor-gallery__item-overlay-content,
.e-gallery-item:hover .elementor-gallery__item-overlay-content__description,
.e-gallery-item:hover .elementor-gallery__item-overlay-content__title {
    opacity: 1
}

a.elementor-item.elementor-gallery-title {
    color: var(--galleries-title-color-normal)
}

a.elementor-item.elementor-gallery-title.elementor-item-active,
a.elementor-item.elementor-gallery-title.highlighted,
a.elementor-item.elementor-gallery-title:focus,
a.elementor-item.elementor-gallery-title:hover {
    color: var(--galleries-title-color-hover)
}

a.elementor-item.elementor-gallery-title.elementor-item-active {
    color: var(--gallery-title-color-active)
}

.e-con-inner>.elementor-widget-gallery,
.e-con>.elementor-widget-gallery {
    width: var(--container-widget-width, 100%)
}
</style>

<style scoped>
/*! elementor - v3.11.2 - 22-02-2023 */
.elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-mobile-0 .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-tablet-0 .elementor-widget-container {
    line-height: 1;
    font-size: 0
}

.elementor-widget-social-icons:not(.elementor-grid-0):not(.elementor-grid-tablet-0):not(.elementor-grid-mobile-0) .elementor-grid {
    display: inline-grid
}

.elementor-widget-social-icons .elementor-grid {
    grid-column-gap: var(--grid-column-gap, 5px);
    grid-row-gap: var(--grid-row-gap, 5px);
    grid-template-columns: var(--grid-template-columns);
    justify-content: var(--justify-content, center);
    justify-items: var(--justify-content, center)
}

.elementor-icon.elementor-social-icon {
    font-size: var(--icon-size, 25px);
    line-height: var(--icon-size, 25px);
    width: calc(var(--icon-size, 25px) + (2 * var(--icon-padding, .5em)));
    height: calc(var(--icon-size, 25px) + (2 * var(--icon-padding, .5em)))
}

.elementor-social-icon {
    --e-social-icon-icon-color: #fff;
    display: inline-flex;
    background-color: #818a91;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer
}

.elementor-social-icon i {
    color: var(--e-social-icon-icon-color)
}

.elementor-social-icon svg {
    fill: var(--e-social-icon-icon-color)
}

.elementor-social-icon:last-child {
    margin: 0
}

.elementor-social-icon:hover {
    opacity: .9;
    color: #fff
}

.elementor-social-icon-android {
    background-color: #a4c639
}

.elementor-social-icon-apple {
    background-color: #999
}

.elementor-social-icon-behance {
    background-color: #1769ff
}

.elementor-social-icon-bitbucket {
    background-color: #205081
}

.elementor-social-icon-codepen {
    background-color: #000
}

.elementor-social-icon-delicious {
    background-color: #39f
}

.elementor-social-icon-deviantart {
    background-color: #05cc47
}

.elementor-social-icon-digg {
    background-color: #005be2
}

.elementor-social-icon-dribbble {
    background-color: #ea4c89
}

.elementor-social-icon-elementor {
    background-color: #d30c5c
}

.elementor-social-icon-envelope {
    background-color: #ea4335
}

.elementor-social-icon-facebook,
.elementor-social-icon-facebook-f {
    background-color: #3b5998
}

.elementor-social-icon-flickr {
    background-color: #0063dc
}

.elementor-social-icon-foursquare {
    background-color: #2d5be3
}

.elementor-social-icon-free-code-camp,
.elementor-social-icon-freecodecamp {
    background-color: #006400
}

.elementor-social-icon-github {
    background-color: #333
}

.elementor-social-icon-gitlab {
    background-color: #e24329
}

.elementor-social-icon-globe {
    background-color: #818a91
}

.elementor-social-icon-google-plus,
.elementor-social-icon-google-plus-g {
    background-color: #dd4b39
}

.elementor-social-icon-houzz {
    background-color: #7ac142
}

.elementor-social-icon-instagram {
    background-color: #262626
}

.elementor-social-icon-jsfiddle {
    background-color: #487aa2
}

.elementor-social-icon-link {
    background-color: #818a91
}

.elementor-social-icon-linkedin,
.elementor-social-icon-linkedin-in {
    background-color: #0077b5
}

.elementor-social-icon-medium {
    background-color: #00ab6b
}

.elementor-social-icon-meetup {
    background-color: #ec1c40
}

.elementor-social-icon-mixcloud {
    background-color: #273a4b
}

.elementor-social-icon-odnoklassniki {
    background-color: #f4731c
}

.elementor-social-icon-pinterest {
    background-color: #bd081c
}

.elementor-social-icon-product-hunt {
    background-color: #da552f
}

.elementor-social-icon-reddit {
    background-color: #ff4500
}

.elementor-social-icon-rss {
    background-color: #f26522
}

.elementor-social-icon-shopping-cart {
    background-color: #4caf50
}

.elementor-social-icon-skype {
    background-color: #00aff0
}

.elementor-social-icon-slideshare {
    background-color: #0077b5
}

.elementor-social-icon-snapchat {
    background-color: #fffc00
}

.elementor-social-icon-soundcloud {
    background-color: #f80
}

.elementor-social-icon-spotify {
    background-color: #2ebd59
}

.elementor-social-icon-stack-overflow {
    background-color: #fe7a15
}

.elementor-social-icon-steam {
    background-color: #00adee
}

.elementor-social-icon-stumbleupon {
    background-color: #eb4924
}

.elementor-social-icon-telegram {
    background-color: #2ca5e0
}

.elementor-social-icon-thumb-tack {
    background-color: #1aa1d8
}

.elementor-social-icon-tripadvisor {
    background-color: #589442
}

.elementor-social-icon-tumblr {
    background-color: #35465c
}

.elementor-social-icon-twitch {
    background-color: #6441a5
}

.elementor-social-icon-twitter {
    background-color: #1da1f2
}

.elementor-social-icon-viber {
    background-color: #665cac
}

.elementor-social-icon-vimeo {
    background-color: #1ab7ea
}

.elementor-social-icon-vk {
    background-color: #45668e
}

.elementor-social-icon-weibo {
    background-color: #dd2430
}

.elementor-social-icon-weixin {
    background-color: #31a918
}

.elementor-social-icon-whatsapp {
    background-color: #25d366
}

.elementor-social-icon-wordpress {
    background-color: #21759b
}

.elementor-social-icon-xing {
    background-color: #026466
}

.elementor-social-icon-yelp {
    background-color: #af0606
}

.elementor-social-icon-youtube {
    background-color: #cd201f
}

.elementor-social-icon-500px {
    background-color: #0099e5
}

.elementor-shape-rounded .elementor-icon.elementor-social-icon {
    border-radius: 10%
}

.elementor-shape-circle .elementor-icon.elementor-social-icon {
    border-radius: 50%
}
</style>

<style scoped type="text/css">
.spancontent {
    padding-bottom: 20px;
}

.copy-content {
    color: #6EC1E4;
    text-align: center;
}

.head-title {
    color: #6EC1E4;
    text-align: center;
}
</style>

<style scoped type="text/css">
.spancontent {
    padding-bottom: 20px;
}

.copy-content {
    color: #6EC1E4;
    text-align: center;
}

.head-title {
    color: #6EC1E4;
    text-align: center;
}
</style>